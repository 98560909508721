.crosswords {
  display: flex;
  padding: 20px;

  .crosswords-box {
    width: 80px;
    height: 80px;
    border: 2px solid #000;
  }
}
