.new-question-modal {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 80px;

  .new-question-modal--header {
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
  }

  .new-question-modal--body {
    gap: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .level-select {
      gap: 5px;
      width: 100%;
      display: flex;
      flex-direction: column;

      label {
        font-weight: 500;
      }
    }

    .app-center--body--button {
      gap: 10px;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      button {
        gap: 5px;
        padding: 6px;
        font-size: 14px;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .action-search-panel {
      top: 114px;
      position: absolute;
      flex-direction: column;
      max-height: 160px;
      background-color: #ffffff;
      border-radius: 3px;
      overflow: auto;
      gap: 4px;
      display: flex;
      width: 96%;
      border: 1px solid #c6c6c6;

      .action-search-panel--item {
        border-bottom: 1px solid #c6c6c6;
        padding: 1px 10px;

        .action-search-panel--item--name {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  .new-question-modal--body--loading {
    gap: 10px;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 20vw;
    height: 100%;
    flex-direction: column;
    backdrop-filter: blur(4px);
    background-color: rgba(255, 255, 255, 0.4);

    label {
      color: #50526a;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
