// 480px
@media #{"only screen and (max-width: 480px)"} {
  .app {
    overflow: auto;
    flex-direction: column;

    .app-left {
      display: none !important;
    }

    .app-center {
      width: 100% !important;
    }

    .app-right {
      width: 100% !important;

      h4 {
        font-size: 16px;
      }

      h6 {
        font-size: 12px;
      }

      .app-right--body {
        width: 100vw !important;
        overflow: auto;
      }
    }
  }
}
@font-face {
  font-family: "Barlow-ExtraLight";
  src:
    local("Barlow-ExtraLight"),
    url("./assets/fonts/Barlow-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Barlow-Light";
  src:
    local("Barlow-Light"),
    url("./assets/fonts/Barlow-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Barlow-Regular";
  src:
    local("Barlow-Regular"),
    url("./assets/fonts/Barlow-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Barlow-Medium";
  src:
    local("Barlow-Medium"),
    url("./assets/fonts/Barlow-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Barlow-Thin";
  src:
    local("Barlow-Thin"),
    url("./assets/fonts/Barlow-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Barlow-Bold";
  src:
    local("Barlow-Bold"),
    url("./assets/fonts/Barlow-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Barlow-SemiBold";
  src:
    local("Barlow-SemiBold"),
    url("./assets/fonts/Barlow-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Barlow-ExtraBold";
  src:
    local("Barlow-ExtraBold"),
    url("./assets/fonts/Barlow-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Barlow-Black";
  src:
    local("Barlow-Black"),
    url("./assets/fonts/Barlow-Black.ttf") format("truetype");
}
