.app {
  width: 100%;
  display: flex;
  height: 100vh;
  flex-direction: row;
  justify-content: space-between;

  .app-left {
    display: flex;
    flex-direction: column;
    background-color: #50526a;

    .app-left--image {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;

      img {
        width: 80px;
        height: 80px;
      }
    }
  }

  .app-right {
    width: calc(100% - 120px);
    display: flex;
    flex-direction: column;
    border-left: 1px solid #c6c6c6;

    .app-right--header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px;

      h2 {
        margin: 0;
        padding: 10px;
      }

      h6 {
        margin: 0;
      }

      .app-right--header--filters {
        gap: 5px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;

        input {
          width: 300px;
        }

        button {
          &:hover {
            color: #000000;
            background-color: #dcdbdb;
          }
        }
      }

      .app-right--header--add-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background-color: #f5793b;
        color: #ffffff;
        border: 1px solid #f5793b;
        cursor: pointer;

        &:hover {
          background-color: #ff5500;
          border-color: #ff5500;
        }
      }
    }

    .app-right--body {
      width: 100%;
      padding: 0 20px;
      background: linear-gradient(to bottom, #ffffff 0%, rgba(176, 176, 176, 0.1) 100%);

      div {
        max-height: 80vh;
        display: flex;
        overflow: auto;
        background-color: #ffffff;

        table {
          width: 100%;
          border: 1px solid lightgray;

          thead {
            th {
              padding: 2px 10px;
              white-space: nowrap;
              border-bottom: 1px solid lightgray;
              border-right: 1px solid lightgray;
            }
          }

          tbody {
            // display: inline-table;
            border-bottom: 1px solid lightgray;

            tr {
              height: 30px;
              cursor: pointer;

              td {
                padding: 0 10px;
                white-space: nowrap;
                align-self: center;
                border-bottom: 1px solid lightgray;
                border-right: 1px solid lightgray;

                label {
                  margin: 0;
                }
              }

              &:hover {
                background-color: rgba(213, 212, 212, 0.3);
              }
            }
          }

          tfoot {
            color: gray;
          }

          tfoot th {
            font-weight: normal;
          }
        }

        .td-long-text {
          background-color: #ffd5b4;
        }
      }
    }

    .app-right--footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
    }

    .app-right--loading {
      gap: 10px;
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;
      width: 75vw;
      height: 100%;
      flex-direction: column;
      backdrop-filter: blur(4px);
      background-color: rgba(255, 255, 255, 0.4);

      label {
        color: #50526a;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
