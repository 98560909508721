
.input-title-group {
  gap: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;

  label {
    font-weight: 500;
  }

  textarea {
    padding: 4px 10px;
    font-size: 0.98rem;
    border-radius: 4px;
    border: 1px solid #cccccc;
  }

  input {
    padding: 0 10px;
    font-size: 0.98rem;
    height: 34px;
    border-radius: 4px;
    border: 1px solid #cccccc;
  }
}