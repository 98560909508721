.signin {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  background-color: #1e2e3d;
  padding: 60px;

  .signin--body {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    height: 100%;
    width: 100%;
    border-radius: 20px;
    box-shadow: 10px 10px 0px 0px rgba(232, 240, 254, 0.2);

    .signin--body--left {
      background-color: #2c4359;
      flex: 1;
      height: 100%;
      border-radius: 20px 0 0 20px;
      border-style: groove;
      border-color: rgb(232, 240, 254);
      border-width: 0 1px 0 0;

      .signin--body--left--image {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 60px;
        padding-bottom: 0px;

        img {
          width: 240px;
          height: 240px;
        }
      }

      .signin--body--left--title {
        color: #ffffff;
        text-align: center;
        // margin-top: 30px;
        font-family: "Barlow-Bold";
        text-transform: uppercase;
      }
    }

    .signin--body--right {
      display: flex;
      background-color: #354a5e;
      flex: 1;
      height: 100%;
      border-radius: 0px 20px 20px 0px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-style: groove;
      border-color: #e8f0fe;
      border: none;

      label {
        font-family: "Barlow-Bold";
        width: 20%;
        color: #1e2e3d;
        text-align: right;
        background-color: transparent;
      }

      input {
        width: 200px;
        height: 40px;
        padding: 0 20px;
        border-radius: 10px;
        border: none;
        background-color: rgb(232, 240, 254);

        &:focus-visible {
          border: none;
          outline: none;
          background-color: rgb(232, 240, 254);
        }
      }

      .signin--body--right--username {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
        font-family: "Barlow-Medium";
        background-color: rgb(232, 240, 254);
        padding-left: 20px;
        border-radius: 50px;
        padding-right: 20px;
      }

      .signin--body--right--password {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
        font-family: "Barlow-Medium";
        background-color: rgb(232, 240, 254);
        border: none;
        padding-left: 20px;
        border-radius: 50px;
        padding-right: 20px;
      }
    }

    .signin--body--right--enter-button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;

      button {
        color: #ffffff;
        width: 60%;
        padding: 4px 40px;
        border-radius: 50px;
        border: 2px solid #f5793b;
        background-color: #f5793b;
        font-family: "Barlow-Bold";
      }
    }
  }
}
