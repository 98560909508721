.privacy-policy {
  padding-top: 3%;
  padding-left: 20%;
  padding-right: 20%;
  font-size: 1em;
  line-height: 1.5em;
  text-align: justify;
  color: #333;
  background-color: #fff;
  border-radius: 5px;
  font-family: "Barlow", sans-serif;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h2 {
    padding-bottom: 20px;
  }

  h3 {
    padding-top: 20px;
  }
}
