.remove-player {
  display: flex;
  width: 100%;
  padding-top: 3%;
  padding-left: 20%;
  padding-right: 20%;
  font-size: 1em;
  line-height: 1.5em;
  text-align: justify;
  color: #333;
  background-color: #fff;
  border-radius: 5px;
  font-family: "Barlow", sans-serif;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    padding-bottom: 20px;
  }

  h3 {
    padding-top: 20px;
  }
}

.remove-player--body {
  gap: 10px;
  padding: 80px;
  display: flex;
  width: 60%;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.remove-player--body--image {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  img {
    border-radius: 20px;
    width: 140px;
    height: 140px;
  }
}

.remove-player--body--info {
  gap: 10px;
  display: flex;
  flex-direction: column;

  label {
    margin: 0;
    font-size: 1em;
    font-weight: 500;
  }

  span {
    font-size: 12px;
    font-weight: 400;
  }
}

.remove-player--button {
  width: 40%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-family: "Barlow", sans-serif;
  background-color: #ff0000;
  font-weight: 600;
  border-color: #ff0000;
  padding: 10px 20px;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .remove-player {
    padding-left: 10px;
    padding-right: 10px;
  }

  .remove-player--body {
    padding: 40px;
    width: 100%;
  }

  .remove-player--body--image {
    img {
      width: 100px;
      height: 100px;
    }
  }

  .remove-player--button {
    width: 60%;
  }
}
